import React, { ReactNode } from "react";
import Head from "next/head";

import Header from "./header";
import Footer from "./footer";
import LeftMenu from "../leftMenu/leftMenu";
import TopNavi from "./topNavi";
import dynamic from "next/dynamic";

interface LayoutProps {
  children?: ReactNode;
  title?: string | null;
  navActive?: number;
  showFooter?: boolean;
  classList?: string;
}

const ChannelTalk = dynamic(() => import("./../common/channelTalk"), {
  ssr: false,
});

const Layout: React.FC<LayoutProps> = ({
  children,
  title = "",
  navActive = 0,
  showFooter = true,
  classList = "",
}: LayoutProps) => {
  const strTitle: string = typeof title === "string" ? title : "";

  return (
    <React.Fragment>
      {/* <Head> */}
      {/* <title>{strTitle}</title> */}
      {/* <meta charSet="utf-8" /> */}
      {/* </Head> */}
      <div className="globalContainer">
        {/* <div className="globalTop">
                <TopNavi />
            </div> */}
        <div className="globalContent">
          {/* <div className="globalLeft">
            <LeftMenu navActive={navActive} isFixed={false} />
          </div> */}
          <div className={"globalMid" + " " + classList}>{children}</div>
          {/* <div className="globalRight">

                </div> */}
        </div>

        {/* {typeof window !== "undefined" && <ChannelTalk />} */}

        {/* {showFooter === true ? <Footer /> : null} */}
      </div>
    </React.Fragment>
  );
};

export default Layout;
